import { Button } from '@mui/material';
import { ComponentType, lazy } from 'react';

// TODO: 依存関係が限定的なので packages/core/ などで共通化したい
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazyImport = (factory: () => Promise<{ default: ComponentType<any> }>) =>
  lazy(async () => {
    try {
      const component = await factory();
      window.sessionStorage.removeItem('lazyImport-force-reload');
      return component;
    } catch {
      if (!window.sessionStorage.getItem('lazyImport-force-reload')) {
        window.sessionStorage.setItem('lazyImport-force-reload', 'true');
        window.location.reload();
        return { default: () => <></> };
      }
      return {
        default: () => (
          <>
            <h1>画面の読み込みに失敗しました</h1>
            <Button onClick={() => window.location.reload()}>
              再読み込みする
            </Button>
          </>
        ),
      };
    }
  });

export default lazyImport;
