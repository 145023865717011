import {
  Navigate,
  RouteObject,
  useLocation,
  useParams,
} from 'react-router-dom';

import lazyImport from '@/Pages/LazyImport';

const Main = lazyImport(() => import('./Pages/Main'));
const TabFrame = lazyImport(() => import('./Pages/TabFrame'));
const BukkenDetailPage = lazyImport(() => import('./Pages/BukkenDetailPage'));
const MaintenancePage = lazyImport(() => import('./Pages/MaintenancePage'));
const NotFoundPage = lazyImport(() => import('./Pages/NotFoundPage'));
const RedirectToOthers = () => {
  const queryParams = useLocation().search;
  const { domainGuid, kokyakuGuid, tsuikyakuGuid } = useParams();
  return (
    <Navigate
      to={`/${domainGuid}/${kokyakuGuid}/${tsuikyakuGuid}/others/${queryParams}`}
    />
  );
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: '/:domainGuid/:kokyakuGuid/:tsuikyakuGuid',
        element: <TabFrame />,
      },
      {
        path: '/:domainGuid/:kokyakuGuid/:tsuikyakuGuid/:tabName',
        element: <TabFrame />,
      },
      {
        path: '/:domainGuid/:kokyakuGuid/:tsuikyakuGuid/:tabName/:contentsName',
        element: <TabFrame />,
      },
      {
        path: '/:domainGuid/:kokyakuGuid/:tsuikyakuGuid/bukken/:bukkenGuid',
        element: <BukkenDetailPage />,
      },
      {
        path: '/:domainGuid/:kokyakuGuid/:tsuikyakuGuid/unsubscribe',
        element: <RedirectToOthers />,
      },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  { path: '404', element: <NotFoundPage /> },
  {
    path: '/:domainGuid/:kokyakuGuid/:tsuikyakuGuid/maintenance',
    element: <MaintenancePage />,
  },
  { path: '/maintenance', element: <MaintenancePage /> },
];

export default routes;
