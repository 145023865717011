import { LoadState } from 'eb-sfa-models';
import useSWR from 'swr';

import { config } from '@/Consts/Config';

export type AppMetadata = {
  version: string;
  forceReload: boolean;
};

/**
 * 最新 の バージョン情報を一定間隔で取得する
 * @param refreshInterval 取得間隔(ミリ秒)
 * @returns フロントエンドアプリのバージョン情報ファイル
 */
export const useVersion = (
  refreshInterval = 300_000,
): LoadState<AppMetadata> => {
  const { data, error, isLoading } = useSWR<AppMetadata>(
    `${config.MYPAGE_URL}/version.json`,
    (input: RequestInfo) =>
      fetch(input)
        .then(res => res.json())
        .catch(_ => {
          // バックグラウンドで定期的に取得するためエラーになっても何もしない
          return;
        }),
    {
      refreshInterval,
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};
