import { CssBaseline, GlobalStyles } from '@mui/material';
import { Theme as MUITheme } from '@mui/material/styles';
import { useRoutes } from 'react-router-dom';
import { SWRConfig } from 'swr';

import routes from '@/Routes';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/consistent-type-definitions
  export interface Theme extends MUITheme {}
}

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      ['*']: {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        color: 'rgba(0,0,0,0.87)',
        backgroundColor: '#f4f6f8',
        height: '100%',
        width: '100%',
        fontSize: '14px',
      },
      a: {
        textDecoration: 'none',
      },
      ['#root']: {
        height: '100%',
        width: '100%',
      },
    }}
  />
);

const App = (): JSX.Element => {
  const routing = useRoutes(routes);

  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        focusThrottleInterval: 0,
        shouldRetryOnError: false,
      }}
    >
      <CssBaseline />
      {inputGlobalStyles}
      {routing}
    </SWRConfig>
  );
};

export default App;
